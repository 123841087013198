import css from './InspireHistoryHeader.module.css';
import DateRangeSnake from '@/status_pages/display/DateRangeSnake';
import DateRangePicker from '@/jskit/react/forms/DateRangePicker';
import moment from 'moment';
import {PdfReportDownloadButton} from '@/status_pages/display/components/PdfReportDownloadButton';
import {GlobalMetrics} from '@/status_pages/display/GlobalMetrics';
import {ComponentStatusValue, StatusPage} from '@/status_pages/types';

export interface InspireHistoryHeaderProps {
  statuspage: StatusPage;
  historyStartDate: object;
  historyEndDate: object;
  historyStartFmt: string;
  historyEndFmt: string;
  handleHistoryDateRangeChange: (start, end) => void;
  hasLoaded: boolean;
  pdfUrl: string;
  componentStatusRank: Record<ComponentStatusValue, number>;
  handleDateRangeSnakeClick: (date: any, e: any) => void;
}
export const InspireHistoryHeader = ({
  statuspage,
  historyStartDate,
  historyEndDate,
  historyStartFmt,
  historyEndFmt,
  handleHistoryDateRangeChange,
  hasLoaded,
  pdfUrl,
  componentStatusRank,
  handleDateRangeSnakeClick,
}: InspireHistoryHeaderProps) => {
  return (
    <div className={css.HistoryHeaderContainer}>
      <div className="row mb-4">
        <div className="col-12 col-lg-10 pl-lg-2">
          <h4 className="d-inline-block mr-4">History</h4>
          <div className="d-inline-block">
            <DateRangePicker
              timezone={window.TIMEZONE}
              enabledDateRanges="day0,day1,7d,30d,mon0,mon1,yr0,yr1"
              nullDateLabel=""
              minDate={
                statuspage.min_visible_date
                  ? moment(statuspage.min_visible_date, 'YYYY-MM-DD').tz(statuspage.used_timezone)
                  : false
              }
              maxDate={moment(statuspage.max_visible_date, 'YYYY-MM-DD').tz(statuspage.used_timezone)}
              startDate={historyStartDate}
              endDate={historyEndDate}
              onChange={handleHistoryDateRangeChange}
            />
          </div>
        </div>
        <div className="col-12 col-lg-2 text-right">
          {statuspage.allow_pdf_report && (
            <PdfReportDownloadButton historyStartFmt={historyStartFmt} historyEndFmt={historyEndFmt} pdfUrl={pdfUrl}>
              Download
            </PdfReportDownloadButton>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4 pl-0">
          {statuspage.show_summary_metrics && hasLoaded ? <GlobalMetrics statuspage={statuspage} /> : null}
        </div>
        <div className="col-12 col-lg-8">
          {hasLoaded ? (
            <DateRangeSnake
              statuspage={statuspage}
              componentStatusRank={componentStatusRank}
              historyStartDate={historyStartDate}
              historyEndDate={historyEndDate}
              onDateRangeSnakeClick={handleDateRangeSnakeClick}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
