'use strict';

import _ from 'underscore';
import moment from 'moment-timezone';

/**
 * Utility functions for client side value formatting.
 */
var Formatter = {};

const dateFormatLookup = {
  DEFAULT: 'MMM DD, YYYY',
  US: 'MM/DD/YYYY',
  EU: 'DD/MM/YYYY',
  ISO: 'YYYY-MM-DD',
};

const timeFormatLookup = {
  DEFAULT: 'h:mm:ssa z',
  DEFAULT_NO_SECONDS: 'h:mma z',
  TWENTY_FOUR_HOUR: 'HH:mm:ss z',
  TWENTY_FOUR_HOUR_NO_SECONDS: 'HH:mm z',
};

Formatter.getCurrentDateFormattedString = function (failureModeFormat) {
  if (window.CURRENT_DATE_FORMAT && window.CURRENT_DATE_FORMAT in dateFormatLookup) {
    return `${dateFormatLookup[window.CURRENT_DATE_FORMAT]}`;
  }
  return failureModeFormat;
};

Formatter.getCurrentDateTimeFormattedString = function (failureModeFormat, timeFormatOverride) {
  if (
    window.CURRENT_DATE_FORMAT &&
    window.CURRENT_DATE_FORMAT in dateFormatLookup &&
    ((window.CURRENT_TIME_FORMAT && window.CURRENT_TIME_FORMAT in timeFormatLookup) || timeFormatOverride)
  ) {
    const timeFormat = timeFormatOverride ? timeFormatOverride : window.CURRENT_TIME_FORMAT;
    return `${dateFormatLookup[window.CURRENT_DATE_FORMAT]} ${timeFormatLookup[timeFormat]}`;
  }
  return failureModeFormat;
};

/**
 * Localize an ISO date currently in UTC as a moment object.
 */
Formatter.localizeDate = function (isodate) {
  if (!isodate) {
    return null;
  }

  var dt = moment.utc(isodate);
  if (window.TIMEZONE !== undefined) {
    dt = dt.tz(window.TIMEZONE);
  }

  return dt;
};

/**
 * Short date-only format.
 */
Formatter.shortDate = function (isodate) {
  if (!isodate) {
    return '';
  }

  var dt = moment.utc(isodate),
    res;
  if (window.TIMEZONE !== undefined) {
    res = dt.tz(window.TIMEZONE).format('MMM D, YYYY');
  } else {
    res = dt.format('MMM D, YYYY');
  }
  return res;
};

/**
 * Short date & time format.
 * differs from longDateTime in that it does not include seconds by default
 */
Formatter.shortDateTime = function (isodate, timeFormatOverride) {
  if (!isodate) {
    return '';
  }

  var dt = moment.utc(isodate),
    res;
  if (window.TIMEZONE !== undefined) {
    const format = Formatter.getCurrentDateTimeFormattedString('MMM D, YYYY h:mma z', timeFormatOverride);
    res = dt.tz(window.TIMEZONE).format(format);
  } else {
    const format = Formatter.getCurrentDateTimeFormattedString('MMM D, YYYY h:mma', timeFormatOverride);
    res = dt.format(format) + ' GMT';
  }
  return res;
};

/**
 * Long date & time format.
 * differs from shortDateTime in that it always includes seconds by default
 */
Formatter.longDateTime = function (isodate, timeFormatOverride) {
  if (!isodate) {
    return '';
  }

  var dt = moment.utc(isodate),
    res;
  if (window.TIMEZONE !== undefined) {
    const fmt = Formatter.getCurrentDateTimeFormattedString('MMM D, YYYY h:mm:ssa z', timeFormatOverride);
    res = dt.tz(window.TIMEZONE).format(fmt);
  } else {
    const fmt = Formatter.getCurrentDateTimeFormattedString('MMM D, YYYY h:mm:ssa', timeFormatOverride);
    res = dt.format(fmt) + ' GMT';
  }
  return res;
};

/**
 * Returns timezone abbreviation for the timezone name.
 */
Formatter.timezoneAbbr = function (timezone) {
  if (timezone === undefined) {
    timezone = window.TIMEZONE;
  }
  return moment(new Date()).tz(timezone).zoneAbbr();
};

/**
 * Rounds a seconds duration to its 2 largest components
 * of days, hours, mins, secs.
 */
Formatter.roundDurationSecs = function (secs) {
  secs = parseFloat(secs);

  if (secs >= 24 * 60 * 60) {
    secs = Math.round(secs / 60 / 60) * 60 * 60;
  } else if (secs >= 60 * 60) {
    secs = Math.round(secs / 60) * 60;
  } else {
    secs = Math.round(secs);
  }

  return parseInt(secs);
};

/**
 * Formats a duration in seconds into it's largest 2 components.
 */
Formatter.shortDuration = function (secs, options) {
  var r = Formatter.roundDurationSecs(secs);

  var s = r % 60;
  r = Math.floor(r / 60);
  var m = r % 60;
  r = Math.floor(r / 60);
  var h = r % 24;
  r = Math.floor(r / 24);
  var d = r;

  var components = _.zip([d, h, m, s], ['d', 'h', 'm', 's']);

  while (components.length > 1 && components[0][0] == 0) {
    components.shift();
  }

  if (options && options.roundSeconds) {
    // if duration is less than a minute - return `< 1m`, round to whole minutes otherwise
    if (components[components.length - 1][1] === 's') {
      if (components.length == 1) {
        if (components[0][0] === 0) {
          return '0s';
        } else {
          return '< 1m';
        }
      } else {
        const shouldAddMinute = Math.round(components[components.length - 1][0] / 60) === 1;
        if (shouldAddMinute) {
          components[components.length - 2][0] = components[components.length - 2][0] + 1;
        }
        components = components.slice(0, components.length - 1);
      }
    }
  }

  components = components.slice(0, 2);
  components = components.map((c) => c[0].toString() + c[1]);
  return components.join(' ');
};

/**
 * Replaces newlines with paragraph breaks.
 */
Formatter.linebreaks = function (text) {
  text = text || '';
  return '<p>' + _.escape(text).trim().replace(/\n+/g, '</p><p>') + '</p>';
};

/**
 * Replaces newlines with line breaks.
 */
Formatter.linebreaksbr = function (text) {
  text = text || '';
  return _.escape(text).trim().replaceAll('\n', '<br />');
};

/**
 * Pad a string.
 */
Formatter.pad = function (n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

/**
 * Truncate a string.
 */
Formatter.truncate = function (v, maxLen) {
  if (!v) {
    return '';
  } else if (v.length <= maxLen) {
    return v;
  }

  return v.substr(0, maxLen - 2) + '...';
};

/**
 * Convert string to title case.
 */
Formatter.titleCase = function (v) {
  return v.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

/**
 * Percentage.
 */
Formatter.percentage = function (v, precision) {
  if (v === null || v === undefined || isNaN(v)) {
    return '';
  }

  precision = precision === undefined || precision === null || isNaN(precision) ? 2 : precision;
  v = (v * 100.0).toFixed(precision).toString();

  if (v.substr(0, 4) === '100.') {
    v = '100';
  }

  return v + '%';
};

/**
 * Seconds.
 */
Formatter.seconds = function (v, precision) {
  precision = precision || 2;
  v = v.toFixed(precision).toString();

  return v + 's';
};

/**
 * Milliseconds from seconds.
 */
Formatter.milliseconds = function (seconds) {
  const v = Math.round(seconds * 1000.0).toString();
  return v + 'ms';
};

/**
 * Formats milliseconds value
 */
Formatter.formatMs = function (ms) {
  const v = Math.round(ms).toString();
  return v + 'ms';
};

/**
 * Automatic seconds/milliseconds duration for seconds.
 */
Formatter.autoDuration = function (v) {
  return v > 1.0 ? Formatter.seconds(v) : Formatter.milliseconds(v);
};

/**
 * Pluralize a string.
 */
Formatter.pluralize = function (num, singular, plural) {
  return num === 1 ? singular : plural;
};

/**
 * Capitalize first letter...
 */
Formatter.capitalizeFirstLetter = function (s) {
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

/**
 * English ordinal number - 1st, 2nd, 3rd, etc
 */
Formatter.ordinalNumber = function (s) {
  s = s.toString();
  const last = s.slice(-1);
  switch (last) {
    case '1':
      return s + 'st';
    case '2':
      return s + 'nd';
    case '3':
      return s + 'rd';
    default:
      return s + 'th';
  }
};

/**
 * Convert alert state to CSS class
 */
Formatter.alertStateCSSClass = function (s) {
  s = s || '';

  const cls = {
    OK: 'success',
    WARNING: 'warning',
    CRITICAL: 'danger',
    UNKNOWN: 'danger',
  }[s.toUpperCase()];

  return cls || '';
};

/**
 * Convert an uptime percentage (0..1) to a CSS class based on the check's SLA.
 */
Formatter.uptimePercentageCSSClass = function (s, uptimeSLA) {
  if (uptimeSLA === undefined) {
    throw 'Formatter.uptimePercentageCSSClass(): uptimeSLA must be provided.';
  }

  if (!uptimeSLA || isNaN(parseFloat(s))) {
    return '';
  } else if (s >= (1.0 + parseFloat(uptimeSLA)) / 2.0) {
    return 'success';
  } else if (s >= parseFloat(uptimeSLA)) {
    return 'warning';
  } else {
    return 'danger';
  }
};

/**
 * Convert a response time (seconds) to CSS class depending on the
 * check's response time SLA.
 */
Formatter.responseTimeCSSClass = function (s, responseTimeSLA) {
  if (responseTimeSLA === undefined) {
    throw 'Formatter.responseTimeCSSClass(): responseTimeSLA must be provided.';
  }

  if (!responseTimeSLA || isNaN(parseFloat(s))) {
    return '';
  }

  if (s <= parseFloat(responseTimeSLA) * 0.7) {
    return 'success';
  } else if (s <= parseFloat(responseTimeSLA)) {
    return 'warning';
  } else {
    return 'danger';
  }
};

/**
 * Convert a RUM load time time (seconds) to CSS class.
 * TODO: RUM: Make this APDEX based per check configuration?
 */
Formatter.rumLoadTimeCSSClass = function (s) {
  if (isNaN(parseFloat(s))) {
    return '';
  }

  if (s <= 5.5) {
    return 'success';
  } else if (s <= 9.0) {
    return 'warning';
  } else {
    return 'danger';
  }
};

Formatter.uptimeGradeCSSClass = function (grade) {
  if (grade === 'A') {
    return 'success';
  } else if (grade === 'B') {
    return 'warning';
  } else {
    return 'danger';
  }
};

/**
 * Determines the name and address to display for a check, and the correct
 * link for more details (service details or rum report)
 */
Formatter.serviceNameAndAddress = function (svc) {
  const nameDisplay = svc.name ? svc.name : svc.device.address;
  const addressDisplay = svc.name || svc.monitoring_service_type === 'HTTP' ? svc.msp_address : '';

  let addressDisplayShort = addressDisplay;
  if (addressDisplayShort.length > 38) {
    addressDisplayShort = addressDisplayShort.substr(0, 35) + '...';
  }

  return {
    name: nameDisplay,
    address: addressDisplay,
    addressShort: addressDisplayShort,
  };
};

/**
 * Determines the correct link for more details for this check
 * (service details or rum report)
 */
Formatter.serviceReportURL = function (svc, defaultDetailsURL, urlMap) {
  var reportURL = defaultDetailsURL + svc.id;

  if (urlMap[svc.monitoring_service_type] != undefined) {
    reportURL = urlMap[svc.monitoring_service_type] + svc.id;
  }

  return reportURL;
};

/**
 * Converts numbers above the threshold to a human-friendly number with the given precision.
 * @param {Object} options Additional function options.
 * Deconstructed so this function behaves with apexcharts.
 * @param {Number} threshold Apply abbreviations above this threshold (e.g. 1.2K). Default 1000.
 */
Formatter.humanFriendlyInt = function (value, {threshold, precision} = {}) {
  if (typeof value !== 'number') {
    return '';
  }
  threshold = threshold === undefined ? 1000 : threshold;
  precision = precision === undefined ? 3 : precision;

  const intVal = parseInt(parseFloat(value.toPrecision(precision)));
  const intString = intVal.toLocaleString();
  if (Math.abs(value) < threshold) {
    return intString;
  }

  const order = intString.replace('-', '').replace(',', '').length - 1;
  let suffix, displayOrder;
  if (order < 3) {
    return intString;
  } else if (order >= 3 && order < 6) {
    suffix = 'K';
    displayOrder = 3;
  } else if (order >= 6 && order < 9) {
    suffix = 'M';
    displayOrder = 6;
  } else if (order >= 9 && order < 12) {
    suffix = 'B';
    displayOrder = 9;
  } else if (order >= 12 && order < 15) {
    suffix = 'T';
    displayOrder = 12;
  } else {
    return intString;
  }
  const fixed = intVal / Math.pow(10, displayOrder);
  return `${fixed}${suffix}`;
};

Formatter.shortCurrency = function (val, maxFracDigits = 0) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maxFracDigits,
  }).format(val);
};

Formatter.humanBytes = function (val) {
  var sizes = [' B', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];

  for (var i = 1; i < sizes.length; i++) {
    if (val < Math.pow(1024, i)) return Math.round((val / Math.pow(1024, i - 1)) * 100) / 100 + sizes[i - 1];
  }
  return val;
};

Formatter.ms = function (v) {
  if (v == undefined || isNaN(v)) {
    return 'n/a';
  }
  var res = '';
  if (v == 0) {
    res = v;
  } else if (v <= 1000) {
    res = v.toFixed(2) + ' ms';
  } else {
    return (v / 1000).toFixed(2) + ' s';
  }
  return res;
};

Formatter.renderFormattedQuantity = (quantity) => {
  if (quantity > 1000000) {
    return `${quantity / 1000000}m`;
  } else if (quantity > 1000) {
    return `${quantity / 1000}k`;
  } else {
    return quantity;
  }
};

Formatter.humanInterval = function (minutes) {
  let humanInterval = `${minutes} minutes`;
  const hours = minutes / 60;
  const days = minutes / 1440;
  if (minutes < 60) {
    humanInterval = `${minutes} minutes`;
  } else if (minutes < 1440) {
    humanInterval = `${hours} hours`;
    if (minutes % 60 !== 0) {
      humanInterval += ` ${minutes} minutes`;
    }
  } else {
    humanInterval = `${days} days`;
    if (minutes % 1440 !== 0) {
      humanInterval += ` ${hours} hours`;
    }
    if (minutes % 60 !== 0) {
      humanInterval += ` ${minutes} minutes`;
    }
  }
  return humanInterval;
};

//****************************
//* EXPORTS
//****************************

export default Formatter;
