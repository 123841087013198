import {CUSTOM_SECTIONS} from './CustomSections';
import {FeatureRow, CustomFeatureRow} from './FeatureRow';
import {
  MonitoringLevelPriority,
  PLAN_FIELD_PLURAL,
  PLAN_FIELD_TOOLTIPS,
  PLAN_FIELD_VALUE,
  PLAN_SPEC_ORDER,
  PLAN_FIELD_UNITS,
  SubscriptionFeature,
} from './PLAN_DEFS';
import classnames from 'classnames';
import React, {ReactNode} from 'react';
import {FeatureRowWarning} from './FeatureRowWarning';
import Formatter from '../../jskit/general/Formatter';
import {ResetLocationsToPlan} from './ResetLocationsToPlan';

interface PlanLimit {
  used: number;
  limit: number;
}

interface Props {
  name: string;
  plan: any;
  resetLocationsURL: string;
  unsupportedChecksURL: string;
  limits: Record<string, PlanLimit>;
}

export const PlanDetails = ({name, plan, limits, resetLocationsURL, unsupportedChecksURL}: Props) => {
  if (name === 'CUSTOM') {
    return (
      <>
        {CUSTOM_SECTIONS.map(({title, tooltip}, index) => {
          return (
            <CustomFeatureRow
              className={`py-1 custom-feature ${index < CUSTOM_SECTIONS.length - 1 ? 'border-bottom' : ''}`}
              key={index}
              value={undefined}
              title={title}
              tooltip={tooltip}
            />
          );
        })}
        <div className="p-1 m-1" data-placement="top" data-toggle="tooltip" title="" data-original-title="">
          <div className="text-center font-14 additional-services">ADDITIONAL SERVICES</div>
        </div>
        <div
          className="p-2 border-bottom"
          data-placement="top"
          data-toggle="tooltip"
          title="Setup a Free Strategy Session with our monitoring experts on how best to monitor your sites and
          services! Additional services available for a small cost."
        >
          <div className="font-20 font-weight-semibold">1 Hour +</div>
          <div className="text-center font-14">Free 1-hour Strategy Session Additional Sessions Available</div>
        </div>
        <div
          className="p-2 border-bottom"
          data-placement="top"
          data-toggle="tooltip"
          title="Add-on Additional customized training sessions for your team and business covering monitoring and
          incident management best practices!"
        >
          <div className="font-20 font-weight-semibold">Custom</div>
          <div className="text-center font-14">Training Sessions</div>
        </div>
        <div
          className="p-2 border-bottom"
          data-placement="top"
          data-toggle="tooltip"
          title="Utilize our Uptime.com experts to setup your monitoring for you at a competitive rate! Contact us
          to learn more!"
        >
          <div>
            <i className="my-3 text-brand-black fa-lg mx-auto fa-solid fa-circle-check"></i>
          </div>
          <div className="text-center font-14">UPro! Services Available</div>
        </div>
      </>
    );
  }
  return (
    <>
      {PLAN_SPEC_ORDER.map((f, idx) => {
        const customValue = (PLAN_FIELD_VALUE[f] || {})[plan[f]];
        const fLimit: {
          limit: number;
          used: number;
        } = (limits || {})[f];
        let infoIcon: ReactNode = null;
        if (fLimit && fLimit.used > fLimit.limit) {
          infoIcon = (
            <FeatureRowWarning
              feature={f}
              plan={Formatter.capitalizeFirstLetter(name)}
              used={fLimit.used}
              limit={fLimit.limit}
            />
          );
        }

        let action: ReactNode = null;
        if (f === SubscriptionFeature.LimitMonitoringGroupLevel && fLimit) {
          const planValue = MonitoringLevelPriority[plan[f]];
          const currentValue = MonitoringLevelPriority[fLimit.used];
          if (planValue < currentValue) {
            action = (
              <ResetLocationsToPlan
                plan={name}
                resetLocationsURL={resetLocationsURL}
                unsupportedChecksURL={unsupportedChecksURL}
              />
            );
          }
        }
        const tooltip = PLAN_FIELD_TOOLTIPS[f];
        let tooltip_value = '';
        if (typeof tooltip === 'string') {
          tooltip_value = tooltip;
        } else if (typeof tooltip === 'object') {
          tooltip_value = tooltip[name];
        }

        const units = PLAN_FIELD_UNITS[f];

        return (
          <FeatureRow
            className={classnames('py-2', {'border-top': idx < PLAN_SPEC_ORDER.length - 1})}
            key={`plan-f-${idx}`}
            value={customValue || plan[f]}
            units={units}
            title={PLAN_FIELD_PLURAL[f]}
            tooltip={tooltip_value}
            infoIcon={infoIcon}
            actionButton={action}
          />
        );
      })}
      {name === 'STARTER' && (
        <>
          <div className="p-1 m-1" data-placement="top" data-toggle="tooltip" title="">
            <div className="text-center font-14 additional-services">ADDITIONAL SERVICES</div>
          </div>
          <div
            className="p-2 border-bottom"
            data-placement="top"
            data-toggle="tooltip"
            title="Setup a Free Strategy Session with our monitoring experts on how best to monitor your sites and
            services! Additional services available for a small cost."
          >
            <div className="font-20 font-weight-semibold position-relative">30 Mins</div>
            <div className="text-center font-14">Free 30-min Strategy Session Additional Sessions Available</div>
          </div>
          <div
            className="p-2 border-bottom"
            data-placement="top"
            data-toggle="tooltip"
            title="Utilize our Uptime.com experts to setup your monitoring for you at a competitive rate! Contact
            us to learn more!"
          >
            <div>
              <i className="my-3 text-brand-gray fa-lg mx-auto fa-solid fa-circle-xmark"></i>
            </div>
            <div className="text-center font-14">UPro! Services Available</div>
          </div>
        </>
      )}
      {(name === 'PREMIUM' || name === 'ESSENTIAL') && (
        <>
          <div className="p-1 m-1" data-placement="top" data-toggle="tooltip" title="">
            <div className="text-center font-14 additional-services">ADDITIONAL SERVICES</div>
          </div>
          <div
            className="p-2 border-bottom"
            data-placement="top"
            data-toggle="tooltip"
            title="Setup a Free Strategy Session with our monitoring experts on how best to monitor your sites and
            services! Additional services available for a small cost."
          >
            <div className="font-20 font-weight-semibold position-relative">1 Hour</div>
            <div className="text-center font-14">Free 1-hour Strategy Session Additional Sessions Available</div>
          </div>
          <div
            className="p-2 border-bottom"
            data-placement="top"
            data-toggle="tooltip"
            title="Utilize our Uptime.com experts to setup your monitoring for you at a competitive rate! Contact us
            to learn more!"
          >
            <div>
              <i className="my-3 text-brand-black fa-lg mx-auto fa-solid fa-circle-check"></i>
            </div>
            <div className="text-center font-14">UPro! Services Available</div>
          </div>
        </>
      )}
    </>
  );
};
