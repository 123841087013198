import {ComponentStatusValue, StatusPage, StatusPageTheme} from '@/status_pages/types';
import {LegacyHistoryHeader} from '@/status_pages/display/components/theme-specific/legacy/LegacyHistoryHeader';
import {InspireHistoryHeader} from '@/status_pages/display/components/theme-specific/inspire/InspireHistoryHeader';

export interface HistoryHeaderProps {
  statuspage: StatusPage;
  historyStartDate: object;
  historyEndDate: object;
  historyStartFmt: string;
  historyEndFmt: string;
  handleHistoryDateRangeChange: (start, end) => void;
  hasLoaded: boolean;
  pdfUrl: string;
  componentStatusRank: Record<ComponentStatusValue, number>;
  handleDateRangeSnakeClick: (date: any, e: any) => void;
}

export const HistoryHeader = ({
  statuspage,
  historyStartDate,
  historyEndDate,
  historyStartFmt,
  historyEndFmt,
  handleHistoryDateRangeChange,
  hasLoaded,
  pdfUrl,
  componentStatusRank,
  handleDateRangeSnakeClick,
}: HistoryHeaderProps) => {
  if (statuspage.theme === StatusPageTheme.INSPIRE) {
    return (
      <InspireHistoryHeader
        statuspage={statuspage}
        historyStartDate={historyStartDate}
        historyEndDate={historyEndDate}
        historyStartFmt={historyStartFmt}
        historyEndFmt={historyEndFmt}
        hasLoaded={hasLoaded}
        pdfUrl={pdfUrl}
        handleHistoryDateRangeChange={handleHistoryDateRangeChange}
        handleDateRangeSnakeClick={handleDateRangeSnakeClick}
        componentStatusRank={componentStatusRank}
      />
    );
  } else {
    return (
      <LegacyHistoryHeader
        statuspage={statuspage}
        historyStartDate={historyStartDate}
        historyEndDate={historyEndDate}
        historyStartFmt={historyStartFmt}
        historyEndFmt={historyEndFmt}
        hasLoaded={hasLoaded}
        pdfUrl={pdfUrl}
        handleHistoryDateRangeChange={handleHistoryDateRangeChange}
        handleDateRangeSnakeClick={handleDateRangeSnakeClick}
        componentStatusRank={componentStatusRank}
      />
    );
  }
};
